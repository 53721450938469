document.addEventListener("turbolinks:load", () => {

  if ( $('#selector1').prop('checked')) {

        
        
        $('.estudiante_comentarios').toggleClass('d-block');
        
        $('.estudiante_direccion_de_tarjetas').toggleClass('d-block');
       
        $('.estudiante_quien_recibe').toggleClass('d-block');
        
        $('.estudiante_horario_tarjeta').toggleClass('d-block');
        
        $('.estudiante_telefono_tarjeta').toggleClass('d-block');
        
    } else {

        $(".tarjeta1").html("Proporciona los datos de la cuenta personal del Estudiante");
        
        
        $('#ocultar').addClass("d-block");
        $('#ocultar').removeClass("d-none");
        
        $('.estudiante_comentarios').removeClass("d-block");
        $('.estudiante_comentarios').addClass('d-none');
        
        $('.estudiante_direccion_de_tarjetas').removeClass("d-block");
        $('.estudiante_direccion_de_tarjetas').addClass('d-none');
        $('.estudiante_quien_recibe').removeClass("d-block");
        $('.estudiante_quien_recibe').addClass('d-none');
        $('.estudiante_horario_tarjeta').removeClass("d-block");
        $('.estudiante_horario_tarjeta').addClass('d-none');
        $('.estudiante_telefono_tarjeta').removeClass("d-block");
        $('.estudiante_telefono_tarjeta').addClass('d-none');
        
    }


	$('#selector1').click(function() {

    

    $('.tarjeta1').text(function(i, text){
        return text === "Deseas que Capital Becarios le genere una nueva tarjeta al Estudiante?" ? "Proporciona los datos de la cuenta personal del Estudiante" : "Deseas que Capital Becarios le genere una nueva tarjeta al Estudiante?";
    })
    $('#ocultar').toggleClass('d-block');
    $('#ocultar').toggleClass('d-none');
    $('.form-check-input').toggleClass('is-valid');
    $('.form-check-input').toggleClass('warning');
    $('.estudiante_comentarios').toggleClass('d-none');
    $('.estudiante_comentarios').toggleClass('d-block');
    $('.estudiante_direccion_de_tarjetas').toggleClass('d-none');
    $('.estudiante_direccion_de_tarjetas').toggleClass('d-block');
    $('.estudiante_quien_recibe').toggleClass('d-none');
    $('.estudiante_quien_recibe').toggleClass('d-block');
    $('.estudiante_horario_tarjeta').toggleClass('d-none');
    $('.estudiante_horario_tarjeta').toggleClass('d-block');
    $('.estudiante_telefono_tarjeta').toggleClass('d-none');
    $('.estudiante_telefono_tarjeta').toggleClass('d-block');
    
	});

    var allNames = [];

    $('.repetido').each(function(index, el) {
        var name = $(el).text();

        if (jQuery.inArray(name, allNames) !== -1) {
            // Si el nombre ya existe en el array, cambia el color de todos los elementos con ese nombre
            $('.repetido').filter(function() {
                return $(this).text() === name;
            }).css('color', 'blueviolet');

            // Añade "original" al primer elemento repetido
            $(el).prepend('- ');
        } else {
            allNames.push(name);
        }
    });

  

  // $('#exampleModal').on('hide.bs.modal', () => {
  //     $('#video').attr('src', '');
  //  });



  // $('#exampleModal').on('hide.bs.modal',  function ()  {
  //   $('#video').attr('src', '');
  //  });
  // $('#exampleModal').on('show.bs.modal',  function ()  {
  //   $('#video').attr('src', '/CONTROL_DE_ESTUDIANTES.mp4');
  //  });








});



  
